import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';

import Layout from "../components/layout"
import SEO from "../components/seo"

import { GiModernCity, GiPaintBrush } from 'react-icons/gi';
import { IoMdConstruct } from 'react-icons/io';
import { FaArchway } from 'react-icons/fa';

import HeroImage from '../images/hero.jpg';
import ObjektiImage from '../images/objekti.png';
import NaslovnaSlika from '../images/slike/naslovna-slike.png';
import Slika1 from '../images/slike/slika-1.png';
import Slika2 from '../images/slike/slika-2.png';
import Slika3 from '../images/slike/slika-3.png';
import Slika4 from '../images/slike/slika-4.png';
import NaslovnaRadovi from '../images/radovi/naslovna-radovi.jpg';
import NaslovnaBiografija from '../images/naslovna-biografija.jpg';
import Licence from '../images/licence.png';
import Nagrade from '../images/nagrade.png';
// import Img from 'gatsby-image';

const OverlayContainer = styled(Container)`
  background-color: rgba(43, 45, 55, 0.9);
  padding: 0px;
  margin: 0px;
`;

const OverlaySubscribe = styled(Container)`
background-color: rgba(250, 188, 61, 0.9);
  padding: 0px;
  margin: 0px;
`;

const SkillSet = styled(Row)`
  font-size: 25px;
`;

const Forma = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 320px;
  }
`;

export const query = graphql`
  query {
    hero: file(relativePath: {eq: "naslovna/hero.jpg"}) {
      childImageSharp {
        fluid {
          base64
          tracedSVG
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
      }
    },
    objekti: file(relativePath: {eq: "naslovna/objekti.png"}) {
      childImageSharp {
        fluid {
          base64
          tracedSVG
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
      }
    },
    biografija: file(relativePath: {eq: "naslovna/naslovna-biografija.jpg"}) {
      childImageSharp {
        fluid {
          base64
          tracedSVG
          srcWebp
          srcSetWebp
          originalImg
          originalName
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const heroImage = data.hero.childImageSharp.fluid;
  const objektiImage = data.objekti.childImageSharp.fluid;
  const biografijaImage = data.biografija.childImageSharp.fluid;

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Home" keywords={[`art`, `arhitektura`, `nz beograd studio`]} />

      <Container mt-5="true" fluid className="hero-area">
        <Container>
          <Row className="row-end">
            <Col xs={12} md={6} className="hero-left">
              <span>NZ Arh and Art Studio
              Beograd</span>
              <Link className="default-button" to="/kontakt">Kontaktirajte nas</Link>
            </Col>
            <Col xs={12} md={6}>
              <img src={HeroImage} alt="logo" style={{ width: '100%' }} />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="objekti-area">
        <Container>
          <Row className="row-end">
            <Col xs={12} md={6} className="hero-left">
              <img src={ObjektiImage} alt="objekti" />
            </Col>
            <Col xs={12} md={6} className="hero-left d-flex justify-content-center align-items-center flex-column">
              <h2 className="display-4 mb-2">Objekti</h2>
              <p>
                Pogledajte slike objekata, projekata, slike izvedenih objekata.
              </p>
              <Link className="default-button font-normal" to="/objekti">Pogledajte naše objekte</Link>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="slike-area">
        <Container>
          <h2 className="display-4 mb-2">Slike</h2>
          <p mb-5="true">
            Pogledajte slike radjene u tehnikama: Gvaš, ulje na platnu, crteže olovkom, digit Art, akvareli...
          </p>
          <Row className="row-end">
            <Col lg={4} md={6} className="image-fit mb-3">
              <img src={NaslovnaSlika} alt="naslovna slike" />
            </Col>
            <Col lg={4} md={6} className="image-fit only-desktop mb-3">
              <img src={Slika1} alt="Slika 1" />
            </Col>
            <Col lg={4} md={6} className="image-fit only-desktop mb-3">
              <img src={Slika2} alt="Slika 2" />
            </Col>
            <Col lg={4} md={6} className="image-fit d-flex justify-content-center align-items-center">
              <Link className="default-button font-normal" to="/slike">Sve Slike</Link>
            </Col>
            <Col lg={4} md={5} className="image-fit only-desktop mb-3">
              <img src={Slika3} alt="Slika 3" />
            </Col>
            <Col lg={4} md={7} className="image-fit only-desktop mb-3">
              <img src={Slika4} alt="Slika 4" />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="radovi-area">
        <Container>
          <Row className="row-end">
            <Col xs={12} md={6} className="hero-left d-flex justify-content-center align-items-center flex-column">
              <h2 className="display-4 mb-2">Radovi u kamenu</h2>
              <p>
                Pogledajte slike radova u kamenu i primenje umetnosti.
              </p>
              <Link className="default-button font-normal" to="/radovi">Pogledajte radove</Link>
            </Col>
            <Col xs={12} md={6} className="only-desktop">
              <img src={NaslovnaRadovi} alt="naslovna radovi" className="maxh-500 regular-shadow" />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="block-iskustvo no-p-m">
        <OverlayContainer fluid className="iskustvo no-p-m text-center pt-4 pb-4">
          <h2 className="display-4 mb-2">Više od 30 godina iskustva</h2>
          <p className="display-5">Pozovite nas</p>
          <h3 className="display-4 mb-2">+381 63 252-069</h3>
        </OverlayContainer>
      </Container>

      <Container className="bigrafija-area">
        <Container>
          <Row className="row-end">
            <Col xs={12} md={6} className="hero-left">
              <img src={NaslovnaBiografija} alt="biografija" />
            </Col>
            <Col xs={12} md={6} className="hero-left d-flex justify-content-center align-items-center flex-column">
              <h2 className="display-4 mb-3 text-center">Nada Jovičić Ivančajić</h2>
              <SkillSet className="d-flex flex-column">
                <p><GiModernCity className="orange-icon" size={45} /> Urbanizam</p>
                <p><IoMdConstruct className="orange-icon" size={45} />Izvođenje</p>
                <p><FaArchway className="orange-icon" size={45} /> Projektovanje</p>
                <p><GiPaintBrush className="orange-icon" size={45} /> Primenjena umetnost</p>
              </SkillSet>
              <Link className="default-button font-normal" to="/biografija">Kompletna Biografija</Link>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="hero-area">
        <Container>
          <Row className="row-end">
            <Col xs={12} md={6} className="text-center image-fit">
              <Link className="default-button font-normal" to="/licence">Naše licence</Link>
              <img src={Licence} alt="Licence" />
            </Col>
            <Col xs={12} md={6} className="image-fit d-flex justify-content-center align-items-center flex-column">
              <Link className="default-button font-normal" to="/nagrade">Nagrade</Link>
              <img src={Nagrade} alt="Nagrade" />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="subscribe-section no-p-m">
        <OverlaySubscribe fluid className="subscribe no-p-m text-center pt-5 pb-5">
          <Container className="text-center">
            <Row>
              <Col xs={12} md={4}>
                <h2 className="display-5 mb-2">Prijavite se na našoj listi</h2>
              </Col>
              <Col xs={12} md={8} className="d-flex">
                <form className="form-inline w-100" name="subskrajberi" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                  <p className="hidden sr-only">
                    <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                  </p>
                  <Forma className="form-group">
                    <label htmlFor="emailSubscription" className="sr-only">Email</label>
                    <input type="email" className="form-control form-control-lg w-100" id="emailSubscription" placeholder="Vaša email adresa" />
                  </Forma>
                  <button type="submit" className="btn btn-outline-secondary btn-lg">Prijavite se</button>
                </form>
              </Col>
            </Row>
          </Container>
        </OverlaySubscribe>
      </Container>

    </Layout >
  );
}

export default IndexPage
